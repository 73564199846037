<template>
  <v-container fill-height fluid>
    
    <v-container id="login" v-if="displogin">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="2"></v-col>
        <v-col cols="8"
          ><center><v-img src="/img/team_main.png" v-show='!toggle' max-width="60%" contain center></v-img
        ></center></v-col>
        
        <v-col cols="2"></v-col>
      </v-row>

      <v-row>
          <v-col cols="12"><center><span class="new_logo" style="color:#9c4b27;">iTeam.</span></center></v-col>
      </v-row>


     <v-row
        ><v-col
          ><span><br />&nbsp;
          <!-- <br />&nbsp;<br />&nbsp; -->
          </span></v-col
        ></v-row
      > 
      <v-form class="login" @submit.prevent="login">
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="msisdn"
              name="msisdn"
              v-maska="'+380 (##) ###-##-##'"  
              label="Номер телефону"
              solo
              rounded
              type="tel"
              autocomplete="tel"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="password"
              name="Password"
              label="Пароль"
              solo
              rounded
              @click:append="show1 = !show1"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              autocomplete="current-password"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
            <v-row
                    ><v-col
                    ><center>
                        <span class="link pass_link" @click="reset_pass_dialog=true"
                        >Забули пароль?<br /></span
                        >
                    </center></v-col
                    ></v-row
                >
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <!-- <v-btn rounded class="primary_button" type="submit">Увійти</v-btn> -->
            <v-btn class="btn_new" type="submit">Увійти</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-form>


<!-- 
      

      <v-row
        ><v-col
          ><center>
            <span class="helvb" style="color: gray"
              >.<br /></span
            >
          </center></v-col
        ></v-row
      > -->

      <v-row
        ><v-col
          ><center>
            <span class="link inter_reg" @click="step_1_go()/*$emit('pushe', {header:'Повідомлення',text:'Функція недоступна, додаток працює у обмеженому режимі'});*/">Реєстрація</span>
          </center></v-col
        ></v-row>

<!-- 
        <v-row
        ><v-col
          ><center>
            <span class="link" @click="step_0_go">Я користувався мобільним додатком</span>
          </center></v-col
        ></v-row> -->
    
    </v-container>



        <v-container id="step_1" v-if="step_1">
          <v-row align="center" justify="center" no-gutters>
       
        <v-col cols="12"
          ><center><v-img src="/img/r1.png" max-width="100%" contain center></v-img
        ></center></v-col>
        
      </v-row>

      <v-row>
          <v-col cols="12"><center><span class="new_logo" style="color:#9c4b27;">iTeam.</span></center></v-col>
      </v-row>


     <v-row
        ><v-col
          ><span><br />&nbsp;
          <!-- <br />&nbsp;<br />&nbsp; -->
          </span></v-col
        ></v-row
      > 
      <v-row
        ><v-col
          ><center><span class="helvb">{{$t('reg')}}</span></center></v-col
        ></v-row
      >
      <v-row
        ><v-col
          ><center>
            <span class="helvb" style="color: gray">{{$t('1of3')}}</span>
          </center></v-col
        ></v-row
      >
      <v-form class="login" @submit.prevent="action_step1">
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
            
              v-model="msisdn_reg"
              name="msisdn_reg"
              :label="$t('enter_phone')"
              solo
              rounded
              v-maska="'+380 (##) ###-##-##'" 
              inputmode="numeric"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row>
                  <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-checkbox v-model="checkbox_18" color="#ee6424" class="ma-0 pa-0">
              <template v-slot:label>
                <div>
                  {{$t('conf_1')}}
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <v-row fluid no-gutters>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="checkbox_agree"
              color="#ee6424"
              class="ma-0 pa-0"
            >
              <template v-slot:label>
                <div>
                  {{$t('conf_2')}}<br>{{$t('conf_2_l')}}
                  <!-- <span class="link" @click="privacy = true"
                    >{{$t('conf_2_l')}}</span
                  > -->
                  
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>


        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn class="btn_new" type="submit">{{$t('btn_next')}}</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-form>
      <v-row
        ><v-col
          ><center>
            <span class="link inter_reg" @click="$router.go()">Повернутися до входу</span>
          </center></v-col
        ></v-row>
     
    </v-container>



     <v-container id="step_2" v-if="step_2">
      <v-row align="center" justify="center" no-gutters>
       
       <v-col cols="12"
         ><center><v-img src="/img/r22.png" max-width="100%" contain center></v-img
       ></center></v-col>
       
     </v-row>

      <v-row>
          <v-col cols="12"><center><span class="new_logo" style="color:#9c4b27;">iTeam.</span></center></v-col>
      </v-row>


     <v-row
        ><v-col
          ><span><br />&nbsp;
          <!-- <br />&nbsp;<br />&nbsp; -->
          </span></v-col
        ></v-row
      > 
      <v-row
        ><v-col
          ><center><span class="helvb">{{$t('reg')}}</span></center></v-col
        ></v-row
      >
      <v-row
        ><v-col
          ><center>
            <span class="helvb" style="color: gray">{{$t('2of3')}}</span>
          </center></v-col
        ></v-row
      >
      <v-form class="login2" @submit.prevent="action_step2">
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="sms_code"
              name="sms_code"
              :label="$t('enter_sms')"
              solo
              rounded    
              v-maska="'####'" 
            ></v-text-field>    
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

         
        <v-row
          ><v-col
            ><center>
              <v-progress-circular
                indeterminate
                color="rgb(238, 100, 36)"
              ></v-progress-circular></center
          ></v-col>
        </v-row>
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn class="btn_new" type="submit">{{$t('btn_next')}}</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-form>
      <v-row
        ><v-col
          ><center>
            <span class="helvb link">{{$t('send_again')}}</span>
          </center></v-col
        ></v-row
      >
      <v-row
        ><v-col
          ><center>
            <span class="link inter_reg" @click="$router.go()">Повернутися до входу</span>
          </center></v-col
        ></v-row>
    </v-container>

    <v-container id="step_3" v-if="step_3">
      <v-row align="center" justify="center" no-gutters>
       
       <v-col cols="12"
         ><center><v-img src="/img/r3.png" max-width="100%" contain center></v-img
       ></center></v-col>
       
     </v-row>

      <v-row>
          <v-col cols="12"><center><span class="new_logo" style="color:#9c4b27;">iTeam.</span></center></v-col>
      </v-row>


     <v-row
        ><v-col
          ><span><br />&nbsp;
          <!-- <br />&nbsp;<br />&nbsp; -->
          </span></v-col
        ></v-row
      > 
      <v-row
        ><v-col
          ><center><span class="helvb">{{$t('reg')}}</span></center></v-col
        ></v-row
      >
      <v-row
        ><v-col
          ><center>
            <span class="helvb" style="color: gray">{{$t('3of3')}}</span>
          </center></v-col
        ></v-row
      >
      <v-form class="login" @submit.prevent="end_reg">
        <!-- <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="password_new"
              name="Password1"
              :label="$t('enter_pass1')"
              solo
              rounded
              @click:append="show1 = !show1"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>-->
        <v-row fluid style="display:none;">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="password_new_confirm"
              name="Password2"
              :label="$t('enter_pass2')"
              solo
              rounded
              @click:append="show2 = !show2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row fluid >
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <center><a @click="pass_gen">Згенерувати надійний пароль</a></center>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

         <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="password_newn"
              name="new-password"
              :label="$t('enter_pass1')"
              solo
              rounded
              @click:append="show3 = !show3"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
              v-model="password_new_confirmn"
              name="new-password-confirm"
              :label="$t('enter_pass2')"
              solo
              rounded
              @click:append="show2 = !show2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn class="btn_new" type="submit"
              >{{$t('btn_next')}}</v-btn
            >
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-form>
<!-- 
      <v-dialog v-model="privacy">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{$t('h_legal')}}
          </v-card-title>

          <v-card-text v-html="$t('legal_txt')">
            
            <br />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="privacy = false">
             {{$t('close')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <v-row
        ><v-col
          ><center>
            <span class="link inter_reg" @click="$router.go()">Повернутися до входу</span>
          </center></v-col
        ></v-row>
    </v-container>





   <v-container id="step_4" v-if="step_4">
    <v-row align="center" justify="center" no-gutters>
       
       <v-col cols="12"
         ><center><v-img src="/img/r4.png" max-width="100%" contain center></v-img
       ></center></v-col>
       
     </v-row>

      <v-row>
          <v-col cols="12"><center><span class="new_logo" style="color:#9c4b27;">iTeam.</span></center></v-col>
      </v-row>


     <v-row
        ><v-col
          ><span><br />&nbsp;
          <!-- <br />&nbsp;<br />&nbsp; -->
          </span></v-col
        ></v-row
      > 
      <v-row
        ><v-col
          ><center><span class="helvb">{{$t('reg')}}</span></center></v-col
        ></v-row
      >
      <v-row
        ><v-col
          ><center>
            <span class="helvb" style="color: gray">{{$t('4of3')}}</span>
          </center></v-col
        ></v-row
      >
      <v-form class="login" @submit.prevent="end_reg2">
        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
       
              v-model="pos_code"
              name="pos_code"
              label="С-"
              solo
              rounded
              v-maska="'C-########'" 
              inputmode="numeric"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <!-- <v-row style="margin-top: -35px;"><v-col cols="1"></v-col>
          <v-col cols="10">
            Оберіть тип торгової точки:
          </v-col>
          <v-col cols="1"></v-col>
        </v-row> -->
          <v-row style="margin-top: -40px;">
                  <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-radio-group v-model="tt_type">
              <v-radio label="Неконтрактна звичайна" value="no_contract_usual"></v-radio>
              <v-radio label="Неконтрактна мережева" value="no_contract_network"></v-radio>
              <v-radio label="TIP" value="tip"></v-radio>
              <v-radio label="KA" value="ka"></v-radio>
            </v-radio-group>
            <!-- <v-checkbox v-model="checkbox_is_network" color="#ee6424" class="ma-0 pa-0">
              <template v-slot:label>
                <div>
                  Позначте, якщо ваша ТТ мережева.
                </div>
              </template>
            </v-checkbox> -->
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>



        <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn class="btn_new" type="submit">{{$t('btn_end')}}</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <confirmdlg ref="confirm" />
      </v-form>
      <v-row
        ><v-col
          ><center>
            <span class="link inter_reg" @click="$router.go()">Повернутися до входу</span>
          </center></v-col
        ></v-row>
     
    </v-container>











<v-dialog v-model="reset_pass_dialog" style="background-image: url('/img/pass_reset.png');background-size: 100%;">
     
        <v-form class="pass_reset" @submit.prevent="pass_reset">
        <v-row fluid>
          
          <v-col cols="12">
            <v-text-field
              v-model="msisdn_reset"
              name="msisdn_reset"
              v-maska="'+380 (##) ###-##-##'" 
              :label="$t('username')"
              solo
              rounded
            ></v-text-field>
          </v-col>
          
        </v-row>
        <v-row fluid>
          
          <v-col cols="12">
            <v-btn class="btn_new" type="submit"
              >{{$t('btn_pass_reset')}}</v-btn
            >
          </v-col>
          
        </v-row>
          </v-form>
        <!-- <v-card-title class="headline grey lighten-2" style="margin-bottom:25px;justify-content:center;">
         
          {{$t('pass_remind')}}  
        </v-card-title>
       
        <v-card-text>
          
          <v-form class="pass_reset" @submit.prevent="pass_reset">
        <v-row fluid>
          
          <v-col cols="12">
            <v-text-field
              v-model="msisdn_reset"
              name="msisdn_reset"
              v-maska="'+380 (##) ###-##-##'" 
              :label="$t('username')"
              solo
              rounded
            ></v-text-field>
          </v-col>
          
        </v-row>
        <v-row fluid>
          
          <v-col cols="12">
            <v-btn class="btn_new" type="submit"
              >{{$t('btn_pass_reset')}}</v-btn
            >
          </v-col>
          
        </v-row>
          </v-form>
          
        </v-card-text> -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="reset_pass_dialog = false">   {{$t('close')}} </span>
        </v-card-actions>
      
    </v-dialog>

    <!-- <v-row fluid>
       <v-col cols="12">
                  <center>
                    технічна підтримка
                 <br><a href="tel:0800330938">0-800-330-938</a>
                 <br>з 10<sup>00</sup> до 17<sup>00</sup> у робочі дні</center>
                 </v-col>
    </v-row> -->
    <div id="sapico" @click='toggle = !toggle'></div>
    <div id="sap" v-show='toggle'></div>
    </v-container>
</template>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

#sapico{
  background-image: url('/img/sapico.png');
  width:48px;height:48px;
  position:absolute;top:5px;right:5px;
}

#sap{
  background-image: url('/img/support.png');
  width:370px;height:312px;
  position:absolute;top:25px;right:0;left:0;margin: auto;
 
}

.new_logo{
  
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 37px;
/* identical to box height */
text-align: center;
letter-spacing: 0.06em;

color: #4B3C32;
}

.pass_link{
  
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
text-decoration-line: underline;

color: #F07300;
}
.inter_reg{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.02em;
text-decoration-line: underline;

color: #5D4F46;
}

.v-label{
  font-size: 13px !important;
}

.btn_new{
  
width: 301px;
height: 49px !important;

background: linear-gradient(90deg, #F07300 0%, #F7B97F 100%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;


font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 23px;
line-height: 28px;
text-align: center;
letter-spacing: 0.02em;

color: #4B3C32 !important;
}

</style>

<script>

import axios from "axios";
import { API_LOCATION } from "../../config";
import confirmdlg from "../components/confirmdlg.vue";
//import l_switch from "../components/lang_switch.vue";
export default {
  watch:{
    

    

  },
  components:{confirmdlg: confirmdlg},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    errorMessage: function () {
      return this.$store.getters.errorMessage;
    },
  },

  data: () => ({
    toggle:false,
    reset_pass_dialog:false,
    interval: {},
    seconds: 30,
    displogin: true,
    step_0: false,
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,

   // iconTeam: require("../assets/LogoITG.svg"),
    tt_type:'',
    privacy: false,
    show1: false,
    show2: false,
    show3: false,
    empty_sms:null,
    msisdn: "380",
    password: "",
    msisdn_reg: "380",
    msisdn_reset:"380",
    pos_code: "C-",
    sms_code: "",
    sms_coden: "",
    sms_codenn: "",
    password_new: "",
    password_new_confirm: "",
    
    password_newn: "",
    password_new_confirmn: "",
    checkbox_18: false,
    checkbox_agree: false,
    checkbox_is_network:false,
    action: "",
    ignore_err:false,
    items: [
      "12 SKU Davidoff  (70 Points)",
      " 12 SKU Davidoff  (80 Points)",
      "  12 SKU Davidoff  (90 Points)",
      "  12 SKU Davidoff  (70 Points)",
    ],
  }), 
  methods: {
    pass_gen: function(){
      var chars = "0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ";
      var passwordLength = 8;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
      }
      if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(password)==false) { this.pass_gen(); }
      else {
        this.password_newn=password
        this.password_new_confirmn=password
        this.show1=true
        this.show2=true
        this.show3=true
        console.log(password)
        }
    },

    step_0_go: function () {
      this.displogin = false;
      this.step_0 = true;
    },
    step_1_go: function () {
      this.displogin = false;
      this.step_0 = false;
      this.step_1 = true;
    },
    action_step1: function () {


      axios
      .post(
        API_LOCATION,
        {
          
          action: "sms_send",
          msisdn: this.msisdn_reg,
          agree1: this.checkbox_18,
          agree2: this.checkbox_agree
          
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.sms_state!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          console.log("go step2");
          this.displogin = false;
          this.step_0 = false;
          this.step_1 = false;
          this.step_2 = true;
          this.sms_coden=0;

          console.log('SMSCODEN'+this.sms_coden+':'+this.sms_codenn);
        
        }
      });
      
    },
    action_step2: function () {
      axios
      .post(
        API_LOCATION,
        {
          
          action: "sms_verify",
          msisdn: this.msisdn_reg,
          // pos: this.pos_code,
          sms: this.sms_code
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.sms_verify!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          console.log("go step3");
          this.displogin = false;
          this.step_0 = false;
          this.step_1 = false;
          this.step_2 = false;
          this.step_3 = true;

        }
      });

      
    },
    end_reg: function () {
      this.show2=false
        this.show3=false
        this.show1=false
        axios
      .post(
        API_LOCATION,
        {
          
          action: "reg_final",
          msisdn: this.msisdn_reg,
          pos: this.pos_code,
          sms: this.sms_code,
          pass1: this.password_newn,
          pass2: this.password_new_confirmn,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.reg_final!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          this.msisdn=this.msisdn_reg;
          this.password=this.password_newn;
         
          console.log("end_reg");
          this.displogin = false;
          this.step_0 = false;
          this.step_1 = false;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = true;
          //this.$router.push("/001");
          
        }
      });
      
    },

    end_reg2: async function () {

        
      

      await axios
      .post(
        API_LOCATION,
        {
          
          action: "reg_final2",
          msisdn: this.msisdn_reg,
          pos: this.pos_code,
          network: this.checkbox_is_network,
          tt_type: this.tt_type,
          ignore_err:this.ignore_err
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then( async (resp) => {
         
        console.log(resp.data);
        if(!this.ignore_err&&resp.data.reg_final!='ok'&&resp.data.debug!='on'&&resp.data.error!='no tip'&&resp.data.error!='no iteam'&&resp.data.error!='no ka')
        {         
          alert(this.$t(resp.data.error));
        } 
        else
        {
          console.log('in first else (almoust done)');

          if(!this.ignore_err&&(resp.data.error=='no tip'||resp.data.error=='no iteam'||resp.data.error=='no ka')){
            console.log('in second else (call confirm)');


            if (        await this.$refs.confirm.open("Підтвердження", this.$t(resp.data.error)))
              { 
                this.ignore_err=true
                this.end_reg2()
              

          } else { console.log('canceled')}

          


          }
          else{
                this.msisdn=this.msisdn_reg;
                this.password=this.password_newn;
               this.login();
                console.log("end_reg2");
                this.displogin = false;
                this.step_0 = false;
                this.step_1 = false;
                this.step_2 = false;
                this.step_3 = true;
         
               this.$router.push("/Main");
          }



        }
      });
      
    },

    login: function () {
      let username = this.msisdn;
      let password = this.password;
      let action = "login";
      this.$store
        .dispatch("login", { action, username, password })
        .then(() =>{ 
          this.$store.dispatch("profileset").then(this.$router.push("/Main"));
          //this.$emit('pushe', {header:'Повідомлення',text:'Шановні партнери!<br>Ми щиро сподіваємось, що ви в безпеці.<br><br>Раді повідомити вам, що роботу додатка відновлено.<br>Завдання найближчих двох тижнів будуть спрямовані на отримання інформації щодо залишків товарного запасу Imperial Tobacco та потреб в забезпеченні асортиментом.'});
          

          
          })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
            alert(this.$t('wrong_login'));
            console.log(err); 
        });
    },
    pass_reset:function(){
            axios
      .post(
        API_LOCATION,
        {
          
          action: "pass_reset",
          msisdn: this.msisdn_reset,
          
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.pass_reset!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          alert(this.$t('wait_sms'));
          this.reset_pass_dialog = false

        }
      });


    }
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/Main");
    }
  },
};
</script>

<i18n>
{
  "ua": {
    "username":"Имя пользователя",
    "pass":"Введіть пароль",
    "enter":"Увійти",
    "t1":"Данное приложение предназначено только для сотрудников торгового объекта, которые принимают непосредственное участие в процессе продажи табачных изделий. Не разрешается предоставлять потребителю доступ к приложению.",
    "reg":"Реєстрація",
    
    "0t1":"Уважаемый партнер!",
    "0t2":"Прежде всего, хотим поблагодарить Вас за плодотворне сотрудничество. ",
    "0t4":"Регистрируйтесь прямо сейчас и получите доступ к полезной информации!",
    "0t5":"Желаем Вам успеха!",
    "0t6":"С уважением,",
    "0t7":"Компания «Империал Тобако»",
    "btn_next":"Продовжити",

    "1of3":"1 з 4",
    "enter_phone":"Введіть номер телефона",
    "enter_pos":"Введите код торговой точки",

    "2of3":"2 з 4",
    "enter_sms":"Введіть SMS код",
    "send_again":"Надіслати SMS код ще раз",

    "3of3":"3 з 4",
    "4of3":"4 з 4",
    "enter_pass1":"Введіть пароль",
    "enter_pass2":"Підтвердження паролю",
    "conf_1":"Так, мені є 21+ років",
    "conf_2":"Так, я згоден на обробку",
    "conf_2_l":"персональних даних",
    
    "btn_end":"Завершити",
 "no type":"Оберіть тип торгової точки, щоб продовжити реєстрацію",
 "no ka":"У базі відсутня відмітка \"KA\" для вказаної точки. Користувач зможе продовжити реєстрацію лише після погодження Адміна. ",
 "no tip":"У базі відсутня відмітка \"TIP\" для вказаної точки. Користувач зможе продовжити реєстрацію лише після погодження Адміна.",
 "no iteam":"У базі торгова точка має статус контрактної. Користувач зможе продовжити реєстрацію лише після погодження Адміна. ",

     "check_pos":"Вказана точка відсутня в базі",
    "check_pos 2 users exist":"На торговій точці вже зареєстрована максимальна кількість продавців.",
    "too much requests":"Перевищено ліміт запитів смс",
    "user exist":"Користувач з таким номером вже зареєстрований",
    "check_num":"Перевірте номер телефону",
    "sms_system":" Помилка відправки смс",
    "wrong code":"Невірний смс-код",
    "pass not match":"Паролі не співпадають",
    "pass insecure":"Пароль має містити від 8 символів, серед яких має бути мінімум 1 цифра, 1 велика літера, 1 маленька літера.",
    "accept all needed":"Приміть умови використання",
    "wrong_login":"Ніверно вказаний номер телефону або пароль.",
    "pass_remind":"Скинути  пароль",
    "btn_pass_reset":"Скинути пароль",
    "wait_sms":"Очікуйте СМС",
    "no_user_reset":"Немає такого користувача",


"h_legal":"Умови використання додатку",
"legal_txt":"",
"close":"Закрити"

  }
}
</i18n>